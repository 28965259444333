html, body {
  height: 100%;
  background: #1a202c;
  margin: 0;
}

#gof canvas {
  display: block;
}

/*# sourceMappingURL=index.79855d43.css.map */
